var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "competition h-96px my-2",
      class: _vm.data.block,
      attrs: { id: "competition-type2" }
    },
    [
      _c(
        "swiper",
        {
          ref: "slider",
          staticClass: "swiper h-100",
          attrs: { options: _vm.swiperOption }
        },
        [
          _vm._l(_vm.competitions, function(competition) {
            return _c(
              "swiper-slide",
              { key: competition._id, staticClass: "card1" },
              [
                _c(
                  "div",
                  { staticClass: "competion__title d-flex px-4 mt-2" },
                  [
                    _c("v-img", {
                      attrs: {
                        "max-width": "20",
                        "max-height": "20",
                        src: competition.game_logo
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "caption title--text ml-1 font-weight-bold"
                      },
                      [_vm._v(_vm._s(competition.competition_name))]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "competion__content d-flex justify-space-between align-center px-4 h-68px"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "competion__content-home w-40 d-flex align-center"
                      },
                      [
                        _c("v-img", {
                          attrs: {
                            "max-width": "32",
                            "max-height": "32",
                            src: competition.home.team_logo
                          }
                        }),
                        _c(
                          "span",
                          { staticClass: "caption title--text ml-2" },
                          [_vm._v(_vm._s(competition.home.team_name))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "competion__content-num primary--text font-weight-bold"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              !competition.match_scoreline
                                ? "VS"
                                : competition.match_scoreline
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "competion__content-away w-40 d-flex justify-end align-center text-right"
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "caption title--text mr-2" },
                          [_vm._v(_vm._s(competition.away.team_name))]
                        ),
                        _c("v-img", {
                          attrs: {
                            "max-width": "32",
                            "max-height": "32",
                            src: competition.away.team_logo
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          }),
          _c("div", {
            staticClass: "swiper-pagination",
            style: _vm.customPaginationColor,
            attrs: { slot: "pagination" },
            slot: "pagination"
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }